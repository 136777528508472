exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-adhd-overview-tsx": () => import("./../../../src/pages/adhd-overview.tsx" /* webpackChunkName: "component---src-pages-adhd-overview-tsx" */),
  "component---src-pages-alpha-testing-tsx": () => import("./../../../src/pages/alpha-testing.tsx" /* webpackChunkName: "component---src-pages-alpha-testing-tsx" */),
  "component---src-pages-beta-testing-tsx": () => import("./../../../src/pages/beta-testing.tsx" /* webpackChunkName: "component---src-pages-beta-testing-tsx" */),
  "component---src-pages-blocked-tsx": () => import("./../../../src/pages/blocked.tsx" /* webpackChunkName: "component---src-pages-blocked-tsx" */),
  "component---src-pages-contact-success-tsx": () => import("./../../../src/pages/ContactSuccess.tsx" /* webpackChunkName: "component---src-pages-contact-success-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-data-delete-instructions-tsx": () => import("./../../../src/pages/data_delete_instructions.tsx" /* webpackChunkName: "component---src-pages-data-delete-instructions-tsx" */),
  "component---src-pages-deck-investor-tsx": () => import("./../../../src/pages/deck/investor.tsx" /* webpackChunkName: "component---src-pages-deck-investor-tsx" */),
  "component---src-pages-deck-product-tsx": () => import("./../../../src/pages/deck/product.tsx" /* webpackChunkName: "component---src-pages-deck-product-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-early-access-subscribed-tsx": () => import("./../../../src/pages/early_access_subscribed.tsx" /* webpackChunkName: "component---src-pages-early-access-subscribed-tsx" */),
  "component---src-pages-early-access-tsx": () => import("./../../../src/pages/early-access.tsx" /* webpackChunkName: "component---src-pages-early-access-tsx" */),
  "component---src-pages-embed-contact-tsx": () => import("./../../../src/pages/embed/contact.tsx" /* webpackChunkName: "component---src-pages-embed-contact-tsx" */),
  "component---src-pages-embed-privacy-tsx": () => import("./../../../src/pages/embed/privacy.tsx" /* webpackChunkName: "component---src-pages-embed-privacy-tsx" */),
  "component---src-pages-embed-terms-tsx": () => import("./../../../src/pages/embed/terms.tsx" /* webpackChunkName: "component---src-pages-embed-terms-tsx" */),
  "component---src-pages-forms-contact-tsx": () => import("./../../../src/pages/forms/contact.tsx" /* webpackChunkName: "component---src-pages-forms-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-internal-testing-tsx": () => import("./../../../src/pages/internal-testing.tsx" /* webpackChunkName: "component---src-pages-internal-testing-tsx" */),
  "component---src-pages-l-[code]-tsx": () => import("./../../../src/pages/l/[code].tsx" /* webpackChunkName: "component---src-pages-l-[code]-tsx" */),
  "component---src-pages-pitch-investor-tsx": () => import("./../../../src/pages/pitch/investor.tsx" /* webpackChunkName: "component---src-pages-pitch-investor-tsx" */),
  "component---src-pages-premium-plan-tsx": () => import("./../../../src/pages/premium_plan.tsx" /* webpackChunkName: "component---src-pages-premium-plan-tsx" */),
  "component---src-pages-premium-subscribed-tsx": () => import("./../../../src/pages/premium_subscribed.tsx" /* webpackChunkName: "component---src-pages-premium-subscribed-tsx" */),
  "component---src-pages-premium-subscription-tsx": () => import("./../../../src/pages/premium_subscription.tsx" /* webpackChunkName: "component---src-pages-premium-subscription-tsx" */),
  "component---src-pages-pricing-tsx": () => import("./../../../src/pages/pricing.tsx" /* webpackChunkName: "component---src-pages-pricing-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-questions-tsx": () => import("./../../../src/pages/questions.tsx" /* webpackChunkName: "component---src-pages-questions-tsx" */),
  "component---src-pages-refund-policy-tsx": () => import("./../../../src/pages/refund_policy.tsx" /* webpackChunkName: "component---src-pages-refund-policy-tsx" */),
  "component---src-pages-students-tsx": () => import("./../../../src/pages/students.tsx" /* webpackChunkName: "component---src-pages-students-tsx" */),
  "component---src-pages-subscribe-tsx": () => import("./../../../src/pages/subscribe.tsx" /* webpackChunkName: "component---src-pages-subscribe-tsx" */),
  "component---src-pages-subscribed-tsx": () => import("./../../../src/pages/subscribed.tsx" /* webpackChunkName: "component---src-pages-subscribed-tsx" */),
  "component---src-pages-support-tsx": () => import("./../../../src/pages/support.tsx" /* webpackChunkName: "component---src-pages-support-tsx" */),
  "component---src-pages-team-tsx": () => import("./../../../src/pages/team.tsx" /* webpackChunkName: "component---src-pages-team-tsx" */),
  "component---src-pages-terms-tsx": () => import("./../../../src/pages/terms.tsx" /* webpackChunkName: "component---src-pages-terms-tsx" */),
  "component---src-pages-test-tsx": () => import("./../../../src/pages/test.tsx" /* webpackChunkName: "component---src-pages-test-tsx" */)
}

